import React, { useState } from "react"
import styled from "styled-components"
import IFrame from "react-iframe"
import Button from "../components/common/StandardButton"
import Layout from "../components/layout"
import { useViewport } from "../hooks/useViewport"
import Modal from "../components/Modal"

const VideoLayerContainer = styled.div`
  display: grid;
  grid-template-columns: 40vw 60vw;
  height: 90vh;
  grid-template-rows: 100%;

  @media (max-width: 1360px) {
    grid-template-columns: 30vw 70vw;
  }

  .left {
    background-color: #af4d3b;
    grid-column: 1;
    display: grid;
    padding-left: 100px;

    @media (max-width: 1360px) {
      padding-left: 60px;
    }

    iframe {
      place-self: center;

      @media (max-width: 1360px) {
        max-width: 480px;
      }
    }
  }

  .right {
    grid-column: 2;
    padding: 80px;
    max-height: 448px;
    justify-self: end;
    align-self: center;

    .subtitle {
      font-size: 18px;
      color: #c84836;
      font-family: "Lato";
    }

    .title {
      font-size: 32px;
      color: #212028;
      font-family: "Chapaza";

      @media (max-width: 1360px) {
        font-size: 28px;
      }
    }

    .text {
      font-family: "Lato";
      font-size: 16px;
      color: #212028;
      max-width: 448px;
      letter-spacing: 2px;
      margin-top: 62px;

      @media (max-width: 1360px) {
        max-width: 360px;
      }
    }
  }
`

const RewardsContainer = styled.div`
  display: grid;
  grid-template-columns: 40vw 60vw;
  height: 60vh;
  grid-template-rows: 100%;

  .left {
    grid-column: 1;
    font-family: "Chapaza";
    font-size: 60px;
    letter-spacing: 2px;
    color: #212028;
    justify-self: center;
    align-self: center;

    @media (max-width: 1360px) {
      font-size: 48px;
    }
  }

  .right {
    grid-column: 2;
    width: 820px;
    justify-self: end;
    padding: 80px;

    @media (max-width: 1360px) {
      width: 640px;
      padding: 40px;
    }
  }
`

const Row = styled.div`
  display: flex;
  height: 73px;
  // width: 100%;
  max-width: 100%;
  flex-direction: row;
  border-bottom: 1px solid rgba(33, 32, 40, 0.3);
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;

  .name {
    font-family: "Lato";
    color: #212028;
    font-size: 16px;

    @media (max-width: 768px) {
      padding-left: 0;
      padding-right: 0;
      margin-left: 5%;
      margin-right: 5%;
      font-size: 14px;
    }
  }

  :hover {
    .name {
      color: #c84836;
    }
  }

  .year {
    font-family: "Lato";
    color: #212028;
    font-size: 16px;
    opacity: 0.5;
  }

  .watch-now {
    width: 200px;
    margin-right: 8px;
  }
`

const MobileContainer = styled.div`
  .subtitle {
    font-size: 18px;
    color: #c84836;
    font-family: "Lato";
    margin: 10% 10% 0% 10%;
  }

  .title {
    font-size: 32px;
    color: #212028;
    font-family: "Chapaza";
    margin: 0% 10% 10% 10%;
  }

  .text {
    font-family: "Lato";
    font-size: 16px;
    color: #212028;
    max-width: 448px;
    letter-spacing: 2px;
    // margin-top: 62px;
    margin: 10%;
  }

  iframe {
    margin: auto;
    margin-bottom: 24px;
  }
`

const RowContainer = ({ onClick }) => (
  <>
    <Row>
      <div
        onClick={() =>
          onClick("https://player.vimeo.com/video/727850759?h=7adba6cbc8")
        }
        className="name"
      >
        Cal Thomas, nationally syndicated columnist and commentator
      </div>
      <Button
        onClick={() =>
          onClick("https://player.vimeo.com/video/727850759?h=7adba6cbc8")
        }
        styles={{ marginRight: "16px", width: "150px" }}
        cursor="pointer"
        color="#C84836"
        text="Watch Now"
      />
      <div className="year">2022</div>
    </Row>
    <Row>
      <div
        onClick={() => onClick("https://player.vimeo.com/video/561591191")}
        className="name"
      >
        Kay Coles James, founder and chair of the Gloucester Institute
      </div>
      <Button
        onClick={() => onClick("https://player.vimeo.com/video/561591191")}
        styles={{ marginRight: "16px", width: "150px" }}
        cursor="pointer"
        color="#C84836"
        text="Watch Now"
      />
      <div className="year">2021</div>
    </Row>
    <Row>
      <div
        onClick={() => onClick("https://player.vimeo.com/video/464054535")}
        className="name"
      >
        Steve Green, President of Hobby Lobby and Chairman of the Museum of the
        Bible
      </div>
      <Button
        onClick={() => onClick("https://player.vimeo.com/video/464054535")}
        styles={{ marginRight: "16px", width: "150px" }}
        cursor="pointer"
        color="#C84836"
        text="Watch Now"
      />
      <div className="year">2020</div>
    </Row>
    <Row onClick={() => onClick("https://player.vimeo.com/video/464045414")}>
      <div className="name">
        Legal scholar and Professor Dr. Robert “Robbie” George
      </div>
      <Button
        onClick={() => onClick("https://player.vimeo.com/video/464045414")}
        styles={{ marginRight: "16px", width: "150px" }}
        cursor="pointer"
        color="#C84836"
        text="Watch Now"
      />
      <div className="year">2019</div>
    </Row>
    <Row onClick={() => onClick("https://player.vimeo.com/video/464036361")}>
      <div className="name">
        Former Attorney General and Counselor to President Reagan Ed Meese
      </div>
      <Button
        onClick={() => onClick("https://player.vimeo.com/video/464036361")}
        styles={{ marginRight: "16px", width: "150px" }}
        cursor="pointer"
        color="#C84836"
        text="Watch Now"
      />
      <div className="year">2018</div>
    </Row>
    <Row onClick={() => onClick("https://player.vimeo.com/video/464013498")}>
      <div className="name">
        Focus on the Family and “Family Talk” founder Dr. James Dobson
      </div>
      <Button
        onClick={() => onClick("https://player.vimeo.com/video/464013498")}
        styles={{ marginRight: "16px", width: "150px" }}
        cursor="pointer"
        color="#C84836"
        text="Watch Now"
      />
      <div className="year">2017</div>
    </Row>
    <Row onClick={() => onClick("https://player.vimeo.com/video/464071349")}>
      <div className="name">
        Conservative talk show host Dennis Prager, founder of Prager University
      </div>
      <Button
        onClick={() => onClick("https://player.vimeo.com/video/464071349")}
        styles={{ marginRight: "16px", width: "150px" }}
        cursor="pointer"
        color="#C84836"
        text="Watch Now"
      />
      <div className="year">2016</div>
    </Row>
  </>
)

const MobileRowContainer = ({ onClick }) => (
  <>
    <Row
      style={{ height: "100px" }}
      onClick={() =>
        onClick("https://player.vimeo.com/video/727850759?h=7adba6cbc8")
      }
    >
      <div>
        <div className="name">
          Cal Thomas, national syndicated columnist and commentator
        </div>
        <Button
          onClick={() =>
            onClick("https://player.vimeo.com/video/727850759?h=7adba6cbc8")
          }
          styles={{
            margin: "unset",
            width: "150px",
            height: "30px",
            paddingLeft: "10px",
            marginBottom: "10px",
          }}
          fontSize="14px"
          cursor="pointer"
          color="#C84836"
          text="Watch Now"
        />
      </div>
      <div className="year">2022</div>
    </Row>
    <Row
      style={{ height: "100px" }}
      onClick={() => onClick("https://player.vimeo.com/video/561591191")}
    >
      <div>
        <div className="name">
          Kay Coles James, founder and chair of the Gloucester Institute
        </div>
        <Button
          onClick={() => onClick("https://player.vimeo.com/video/561591191")}
          styles={{
            margin: "unset",
            width: "150px",
            height: "30px",
            paddingLeft: "10px",
            marginBottom: "10px",
          }}
          fontSize="14px"
          cursor="pointer"
          color="#C84836"
          text="Watch Now"
        />
      </div>
      <div className="year">2021</div>
    </Row>
    <Row
      style={{ height: "100px" }}
      onClick={() => onClick("https://player.vimeo.com/video/464054535")}
    >
      <div>
        <div className="name">
          Steve Green, President of Hobby Lobby and Chairman of the Museum of
          the Bible
        </div>
        <Button
          onClick={() => onClick("https://player.vimeo.com/video/464054535")}
          styles={{
            margin: "unset",
            width: "150px",
            height: "30px",
            paddingLeft: "10px",
            marginBottom: "10px",
          }}
          fontSize="14px"
          cursor="pointer"
          color="#C84836"
          text="Watch Now"
        />
      </div>
      <div className="year">2020</div>
    </Row>
    <Row
      style={{ height: "100px" }}
      onClick={() => onClick("https://player.vimeo.com/video/464045414")}
    >
      <div>
        <div className="name">
          Legal scholar and Professor Dr. Robert “Robbie” George
        </div>
        <Button
          onClick={() => onClick("https://player.vimeo.com/video/464045414")}
          styles={{
            margin: "unset",
            width: "150px",
            height: "30px",
            paddingLeft: "10px",
            marginBottom: "10px",
          }}
          fontSize="14px"
          cursor="pointer"
          color="#C84836"
          text="Watch Now"
        />
      </div>
      <div className="year">2019</div>
    </Row>
    <Row
      style={{ height: "100px" }}
      onClick={() => onClick("https://player.vimeo.com/video/464036361")}
    >
      <div>
        <div className="name">
          Former Attorney General and Counselor to President Reagan Ed Meese
        </div>
        <Button
          onClick={() => onClick("https://player.vimeo.com/video/464036361")}
          styles={{
            margin: "unset",
            width: "150px",
            height: "30px",
            paddingLeft: "10px",
            marginBottom: "10px",
          }}
          fontSize="14px"
          cursor="pointer"
          color="#C84836"
          text="Watch Now"
        />
      </div>
      <div className="year">2018</div>
    </Row>
    <Row
      style={{ height: "100px" }}
      onClick={() => onClick("https://player.vimeo.com/video/464013498")}
    >
      <div>
        <div className="name">
          Focus on the Family and “Family Talk” founder Dr. James Dobson
        </div>
        <Button
          onClick={() => onClick("https://player.vimeo.com/video/464013498")}
          styles={{
            margin: "unset",
            width: "150px",
            height: "30px",
            paddingLeft: "10px",
            marginBottom: "10px",
          }}
          fontSize="14px"
          cursor="pointer"
          color="#C84836"
          text="Watch Now"
        />
      </div>
      <div className="year">2017</div>
    </Row>
    <Row
      style={{ height: "100px" }}
      onClick={() => onClick("https://player.vimeo.com/video/464071349")}
    >
      <div>
        <div className="name">
          Conservative talk show host Dennis Prager, founder of Prager
          University
        </div>
        <Button
          onClick={() => onClick("https://player.vimeo.com/video/464071349")}
          styles={{
            margin: "unset",
            width: "150px",
            height: "30px",
            paddingLeft: "10px",
            marginBottom: "10px",
          }}
          fontSize="14px"
          cursor="pointer"
          color="#C84836"
          text="Watch Now"
        />
      </div>
      <div className="year">2016</div>
    </Row>
  </>
)
const Award = () => {
  const [modalOpen, setModal] = useState(false)
  const [videoURL, setVideoURL] = useState("")
  const { width } = useViewport()
  const breakpoint = 768

  const closeModal = () => setModal(false)

  const onRowClickHandler = videoURL => {
    setModal(true)
    setVideoURL(videoURL)
  }

  return (
    <>
      <Modal isOpen={modalOpen} videoURL={videoURL} onClose={closeModal} />
      {width <= breakpoint ? (
        <MobileContainer>
          <div className="subtitle">William L. Armstrong Award</div>
          <div className="title">
            Faith, family, and <br /> freedom
          </div>
          <div className="text">
            The William L. Armstrong Award is presented annually at the Western
            Conservative Summit, in honor of former businessman, United States
            Senator and Colorado Christian University President William L.
            Armstrong. The award is given to a leader who embodies the values
            that Armstrong held dear – faith, family, and freedom – and who has
            made a difference in America’s culture through business, politics,
            or education.
          </div>
          <IFrame
            url="https://player.vimeo.com/video/856556443?h=b29ca67059"
            width="320"
            height="180"
            allowFullScreen={true}
          />
          <MobileRowContainer onClick={onRowClickHandler} />
        </MobileContainer>
      ) : (
        <>
          <VideoLayerContainer>
            <div className="left">
              <IFrame
                url="https://player.vimeo.com/video/856556443?h=b29ca67059"
                width="640"
                height="360"
                allowFullScreen={true}
              />
            </div>
            <div className="right">
              <div className="subtitle">William L. Armstrong Award</div>
              <div className="title">
                Faith, family, and <br /> freedom
              </div>
              <div className="text">
                The William L. Armstrong Award is presented annually at the
                Western Conservative Summit, in honor of former businessman,
                United States Senator and Colorado Christian University
                President William L. Armstrong. The award is given to a leader
                who embodies the values that Armstrong held dear – faith,
                family, and freedom – and who has made a difference in America’s
                culture through business, politics, or education.
              </div>
            </div>
          </VideoLayerContainer>
          <RewardsContainer>
            <div className="left">
              Recipients of <br /> the award have <br /> included:
            </div>
            <div className="right">
              <RowContainer onClick={onRowClickHandler} />
            </div>
          </RewardsContainer>
        </>
      )}
    </>
  )
}

export default () => (
  <Layout stacked={false}>
    <Award />
    <div style={{ height: "200px" }} />
  </Layout>
)
